<template>
  <div />
</template>

<script>
export default {
  name: 'Debug',
  created() {
    const { token } = this.$route.query
    if (token) {
      sessionStorage.setItem('token', token)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>

</style>
